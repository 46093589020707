import React, { useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useDispatch } from 'react-redux'

import Header from '../header/Header'
import Footer from '../footer/Footer'
import { PATHS } from '../../routes/Routes'
import { ILink } from '../../types'
import StorageService, { loggedInUserKey } from '../../services/StorageService'
import { AuthStatus } from '../../pages/Auth/AuthUtils'
import { fetchFilterOptions } from '../../redux/Filters/FiltersSlice'
import { AppDispatch } from '../../redux/Store'

import './MainLayoutStyles.scss'

interface IMainLayoutProps {
  children: React.ReactNode
}

const MainLayout = (props: IMainLayoutProps) => {
  const dispatch: AppDispatch = useDispatch()
  const { signOut, authStatus, user } = useAuthenticator()
  const footerLinks: ILink[] = [
    {
      label: 'Terms & Conditions',
      link: '',
    },
    {
      label: 'Your Privacy Rights',
      link: '',
    },
    {
      label: 'California Consumer Privacy Rights',
      link: '',
    },
    {
      label: 'Do Not Sell My Information',
      link: '',
    },
  ]
  const headerLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Schedules',
      link: PATHS.SCHEDULES,
      children: [
        {
          label: 'Schedules',
          disabled: true,
          link: '',
        },
        {
          label: 'Export Schedules',
          link: PATHS.SCHEDULES_EXPORT,
        },
        {
          label: 'Import Schedules',
          link: PATHS.SCHEDULES_IMPORT,
        },
      ],
    },
    {
      label: 'Sources',
      link: PATHS.VIEW_SOURCES,
    },
    {
      label: 'Duplicates',
      link: '/duplicates',
      children: [
        {
          label: 'Duplicates',
          disabled: true,
          link: '',
        },
        {
          label: 'Activity',
          link: PATHS.DUPLICATE_ACTIVITY,
        },
        {
          label: 'File Setup',
          link: PATHS.DUPLICATE_FILE_SETUP,
        },
      ],
    },
    {
      label: 'Configurations',
      link: PATHS.CONFIGURATIONS,
      children: [
        {
          label: 'Configurations',
          disabled: true,
          link: '',
        },
        {
          label: 'View Configurations',
          link: PATHS.VIEW_CONFIGURATIONS,
        },
        {
          label: 'Create Configurations',
          link: PATHS.CREATE_CONFIGURATIONS,
        },
        {
          label: 'Bulk',
          disabled: true,
          link: '',
        },
        {
          label: 'Bulk Create',
          link: PATHS.BULK_CREATE_CONFIGURATIONS,
        },
        {
          label: 'Bulk Edit',
          link: PATHS.BULK_EDIT_CONFIGURATIONS,
        },
        {
          label: 'Bulk Delete',
          link: PATHS.BULK_DELETE_CONFIGURATIONS,
        },
        {
          label: 'History',
          link: PATHS.BULK_CONFIGURATIONS_HISTORY,
        },
      ],
    },
    {
      label: 'Audit Log',
      link: PATHS.AUDIT_LOG,
    },
  ]

  const handleSignOut = () => {
    StorageService.removeItem(loggedInUserKey)
    signOut()
  }

  useEffect(() => {
    if (authStatus === AuthStatus.authenticated) {
      dispatch(fetchFilterOptions())
    }
  }, [authStatus])

  return (
    <>
      <Header links={headerLinks} onSignOut={handleSignOut} authStatus={authStatus} user={user} />
      <div className='content-container'>{props.children}</div>
      <Footer links={footerLinks} />
    </>
  )
}

export default MainLayout
